import React from "react";

function BurgerIcon({ open, ...props }) {
  return (
    <div className="text_box">
      <div className={open ? "burger-menu open" : "burger-menu"} {...props}>
        <div className={props.menuColorBar1} key="b1" />
        <div className={props.menuColorBar2} key="b2" />
        <div className={props.menuColorBar3} key="b3" />
      </div>
    </div>
  );
}

export default BurgerIcon;
