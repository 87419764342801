import React from "react";
import Popup from "reactjs-popup";
import BurgerIcon from "./BurgerMenu/BurgerIcon";
import Menu from "../Header/BurgerMenu/Menu";
import headerStyle from "../Header/header.module.css";
import { Desktop, Mobile } from "../utils/resizeVersions";
import Logo from "../../components/Header/Logo/Logo";
import HeaderTitle from "./HeaderTitle/HeaderTitle";

const contentStyle = {
  background: "rgba(255,255,255,0)",
  width: "80%",
  border: "none",
};

function Header(props) {
  return (
    <div>
      <Desktop>
        <div className={headerStyle[props.headerType]}>
          <Popup
            modal
            overlayStyle={{
              background: "rgba(0, 0, 0, 0.6)",
            }}
            contentStyle={contentStyle}
            closeOnDocumentClick={false}
            trigger={(open) => (
              <BurgerIcon
                menuColorBar1={props.Bar1}
                menuColorBar2={props.Bar2}
                menuColorBar3={props.Bar3}
                open={open}
              />
            )}
          >
            {(close) => <Menu close={close} />}
          </Popup>
          <HeaderTitle headingColor={props.headingColor} />
          <Logo logoColor={props.logoColor} />
        </div>
      </Desktop>
      <Mobile>
        <div className={headerStyle.header}>
          <Popup
            modal
            overlayStyle={{
              background: "rgba(0, 0, 0, 0.6)",
            }}
            contentStyle={contentStyle}
            closeOnDocumentClick={false}
            trigger={(open) => (
              <BurgerIcon
                menuColorBar1={props.Bar1}
                menuColorBar2={props.Bar2}
                menuColorBar3={props.Bar3}
                open={open}
              />
            )}
          >
            {(close) => <Menu close={close} />}
          </Popup>
          <HeaderTitle headingColor={props.headingColor} />
          <Logo logoColor={props.logoColor} />
        </div>
      </Mobile>
    </div>
  );
}

export default Header;
