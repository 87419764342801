import style from "./bacara.module.css";
import { useMediaQuery } from "react-responsive";
import Header from "../../Header/Header";
import Footer from "../../Footer/Footer";
import Epic1 from "../../../assets/villas/epic/1.jpg";
import Epic2 from "../../../assets/villas/epic/2.jpg";
import Epic3 from "../../../assets/villas/epic/3.jpg";
import Epic4 from "../../../assets/villas/epic/4.jpg";
import Epic5 from "../../../assets/villas/epic/5.jpg";
import Epic6 from "../../../assets/villas/epic/6.jpg";
import Epic7 from "../../../assets/villas/epic/7.jpg";
import Epic8 from "../../../assets/villas/epic/8.jpg";
import Epic9 from "../../../assets/villas/epic/9.jpg";
import Epic10 from "../../../assets/villas/epic/10.jpg";
import Epic11 from "../../../assets/villas/epic/11.jpg";
import Epic12 from "../../../assets/villas/epic/12.jpg";
import Epic13 from "../../../assets/villas/epic/13.jpg";
import Epic14 from "../../../assets/villas/epic/14.jpg";
import Epic15 from "../../../assets/villas/epic/15.jpg";
import Epic16 from "../../../assets/villas/epic/16.jpg";
import Epic17 from "../../../assets/villas/epic/17.jpg";
import Epic18 from "../../../assets/villas/epic/18.jpg";
import Epic19 from "../../../assets/villas/epic/19.jpg";
import Epic20 from "../../../assets/villas/epic/20.jpg";

import EpicPDF from "../../../assets/pdf/Epic.pdf";
import { Link } from "react-router-dom";
import Button from "../../UI/Button/Button";
import CardProjectDescription from "../../UI/CardProjectDescription/CardProjectDescription";

const Desktop = ({ children }) => {
  const isDesktop = useMediaQuery({ minWidth: 992 });
  return isDesktop ? children : null;
};

const Mobile = ({ children }) => {
  const isMobile = useMediaQuery({ maxWidth: 992 });
  return isMobile ? children : null;
};

const Epic = (props) => {
  return (
    <div>
      <Desktop>
        <Header
          headingColor="heading_primary_projects"
          logoColor="header__logo_projects"
          headerType="header_projects"
          Bar1="bar1_projects"
          Bar2="bar2_projects"
          Bar3="bar3_projects"
        />
        <div className={style.bacara_project}>
          <CardProjectDescription
            title1="EPIC"
            title2="FENDI casa"
            textDescription="Discover a world where life’s greatest
            offerings are ever at your reach.
            Where you’ll awaken to sun-soaked mornings
            kissed by the salty ocean breeze and revel in
            captivating moonlit nights.
            Situated in the heart of Marbella’s famed
            Milla de Oro, EPIC welcomes you to indulge
            in a cosmopolitan alcove, designed for
            those in pursuit of a uniquely exclusive life.
            Luxuries here are as alluring as they are
            accessible, surrounding you with exquisite
            fine dining, exhilarating nightlife, and
            exceptional opportunities for leisure."
          />
          {/* <div>
            <Link to={EpicPDF} target="_blank" rel="noreferrer">
              <Button />
            </Link>
          </div> */}
          <div className={style.bakara_photos}>
            <img src={Epic1} alt="Bacara1"></img>
            <img src={Epic2} alt="Bacara2"></img>
            <img src={Epic3} alt="Bacara2"></img>
            <img src={Epic4} alt="Bacara2"></img>
            <img src={Epic5} alt="Bacara2"></img>
            <img src={Epic6} alt="Bacara2"></img>
            <img src={Epic7} alt="Bacara2"></img>
            <img src={Epic8} alt="Bacara2"></img>
            <img src={Epic9} alt="Bacara2"></img>
            <img src={Epic10} alt="Bacara2"></img>
            <img src={Epic11} alt="Bacara2"></img>
            <img src={Epic12} alt="Bacara2"></img>
            <img src={Epic13} alt="Bacara2"></img>
            <img src={Epic14} alt="Bacara2"></img>
            <img src={Epic15} alt="Bacara2"></img>
            <img src={Epic16} alt="Bacara2"></img>
            <img src={Epic17} alt="Bacara2"></img>
            <img src={Epic18} alt="Bacara2"></img>
            <img src={Epic19} alt="Bacara2"></img>
            <img src={Epic20} alt="Bacara2"></img>
          </div>
        </div>
        <Footer />
      </Desktop>
      <Mobile>
        <Header
          headingColor="heading_primary_projects"
          logoColor="header__logo_projects"
          Bar1="bar1_projects"
          Bar2="bar2_projects"
          Bar3="bar3_projects"
        />
        <div className={style.bacara_project_mobile}>
          <CardProjectDescription
            title1="EPIC"
            title2="FENDI casa"
            textDescription="Discover a world where life’s greatest
            offerings are ever at your reach.
            Where you’ll awaken to sun-soaked mornings
            kissed by the salty ocean breeze and revel in
            captivating moonlit nights.
            Situated in the heart of Marbella’s famed
            Milla de Oro, EPIC welcomes you to indulge
            in a cosmopolitan alcove, designed for
            those in pursuit of a uniquely exclusive life.
            Luxuries here are as alluring as they are
            accessible, surrounding you with exquisite
            fine dining, exhilarating nightlife, and
            exceptional opportunities for leisure."
          />

          {/* <div>
            <Link to={EpicPDF} target="_blank" rel="noreferrer">
              <Button />
            </Link>
          </div> */}
          <div className={style.bakara_photos_mobile}>
            <img src={Epic1} className={style.img_mobile} alt="Bacara1"></img>
            <img src={Epic2} className={style.img_mobile} alt="Bacara2"></img>
            <img src={Epic3} className={style.img_mobile} alt="Bacara2"></img>
            <img src={Epic4} className={style.img_mobile} alt="Bacara2"></img>
            <img src={Epic5} className={style.img_mobile} alt="Bacara2"></img>
            <img src={Epic6} className={style.img_mobile} alt="Bacara2"></img>
            <img src={Epic7} className={style.img_mobile} alt="Bacara2"></img>
            <img src={Epic8} className={style.img_mobile} alt="Bacara2"></img>
            <img src={Epic9} className={style.img_mobile} alt="Bacara2"></img>
            <img src={Epic10} className={style.img_mobile} alt="Bacara2"></img>
            <img src={Epic11} className={style.img_mobile} alt="Bacara2"></img>
            <img src={Epic12} className={style.img_mobile} alt="Bacara2"></img>
            <img src={Epic13} className={style.img_mobile} alt="Bacara2"></img>
            <img src={Epic14} className={style.img_mobile} alt="Bacara2"></img>
            <img src={Epic15} className={style.img_mobile} alt="Bacara2"></img>
            <img src={Epic16} className={style.img_mobile} alt="Bacara2"></img>
            <img src={Epic17} className={style.img_mobile} alt="Bacara2"></img>
            <img src={Epic18} className={style.img_mobile} alt="Bacara2"></img>
            <img src={Epic19} className={style.img_mobile} alt="Bacara2"></img>
            <img src={Epic20} className={style.img_mobile} alt="Bacara2"></img>
          </div>
        </div>
        <Footer />
      </Mobile>
    </div>
  );
};

export default Epic;
