import style from "./bacara.module.css";
import Header from "../../Header/Header";
import Footer from "../../Footer/Footer";
import PhotoFlamingos26_1 from "../../../assets/villas/losFlamingos26/1.jpg";
import PhotoFlamingos26_2 from "../../../assets/villas/losFlamingos26/2.jpg";
import PhotoFlamingos26_3 from "../../../assets/villas/losFlamingos26/3.jpg";
import PhotoFlamingos26_4 from "../../../assets/villas/losFlamingos26/4.jpg";
import PhotoFlamingos26_5 from "../../../assets/villas/losFlamingos26/5.jpg";
import PhotoFlamingos26_6 from "../../../assets/villas/losFlamingos26/6.jpg";
import PhotoFlamingos26_7 from "../../../assets/villas/losFlamingos26/7.jpg";
import PhotoFlamingos26_8 from "../../../assets/villas/losFlamingos26/8.jpg";
import PhotoFlamingos26_9 from "../../../assets/villas/losFlamingos26/9.jpg";
import PhotoFlamingos26_10 from "../../../assets/villas/losFlamingos26/10.jpg";
import PhotoFlamingos26_11 from "../../../assets/villas/losFlamingos26/11.jpg";
import Flamingos26Pdf from "../../../assets/pdf/Villa_Flamingos26.pdf";
import { Link } from "react-router-dom";
import Button from "../../UI/Button/Button";
import { Desktop, Mobile } from "../../utils/resizeVersions";
import CardProjectDescription from "../../UI/CardProjectDescription/CardProjectDescription";

const Flamingos26 = (props) => {
  return (
    <div>
      <Desktop>
        <Header
          headingColor="heading_primary_projects"
          logoColor="header__logo_projects"
          headerType="header_projects"
          Bar1="bar1_projects"
          Bar2="bar2_projects"
          Bar3="bar3_projects"
        />
        <div className={style.bacara_project}>
          <CardProjectDescription
            title1="Villa Egret"
            title2="Benahavis"
            textDescription="Villa Egret is an exquisite and luxurious property built in a
            unique environment, balancing form, texture and space to create
            the best home. It was designed according to the highest market
            standards and the most avant-garde technology to know at all times
            the state of the home and offer maximum comfort. This impressive
            villa is specially designed to offer breathtaking panoramic views
            of the beautiful surroundings and the Mediter- ranean Sea. A
            guarded private urbanization allows you to feel calm and safe."
          />

          <div>
            <Link to={Flamingos26Pdf} target="_blank" rel="noreferrer">
              <Button />
            </Link>
          </div>
          <div className={style.bakara_photos}>
            <img src={PhotoFlamingos26_1} alt="PhotoFlamingos26"></img>
            <img src={PhotoFlamingos26_2} alt="PhotoFlamingos26"></img>
            <img src={PhotoFlamingos26_3} alt="PhotoFlamingos26"></img>
            <img src={PhotoFlamingos26_4} alt="PhotoFlamingos26"></img>
            <img src={PhotoFlamingos26_5} alt="PhotoFlamingos26"></img>
            <img src={PhotoFlamingos26_6} alt="PhotoFlamingos26"></img>
            <img src={PhotoFlamingos26_7} alt="PhotoFlamingos26"></img>
            <img src={PhotoFlamingos26_8} alt="PhotoFlamingos26"></img>
            <img src={PhotoFlamingos26_9} alt="PhotoFlamingos26"></img>
            <img src={PhotoFlamingos26_10} alt="PhotoFlamingos26"></img>
            <img src={PhotoFlamingos26_11} alt="PhotoFlamingos26"></img>
          </div>
        </div>
        <Footer />
      </Desktop>
      <Mobile>
        <Header
          headingColor="heading_primary_projects"
          logoColor="header__logo_projects"
          Bar1="bar1_projects"
          Bar2="bar2_projects"
          Bar3="bar3_projects"
        />
        <div className={style.bacara_project_mobile}>
          <CardProjectDescription
            title1="Villa Egret"
            title2="Benahavis"
            textDescription="Villa Egret is an exquisite and luxurious property built in a
            unique environment, balancing form, texture and space to create
            the best home. It was designed according to the highest market
            standards and the most avant-garde technology to know at all times
            the state of the home and offer maximum comfort. This impressive
            villa is specially designed to offer breathtaking panoramic views
            of the beautiful surroundings and the Mediter- ranean Sea. A
            guarded private urbanization allows you to feel calm and safe."
          />

          <div>
            <Link to={Flamingos26Pdf} target="_blank" rel="noreferrer">
              <Button />
            </Link>
          </div>
          <div className={style.bakara_photos_mobile}>
            <img
              src={PhotoFlamingos26_1}
              className={style.img_mobile}
              alt="PhotoFlamingos26"
            ></img>
            <img
              src={PhotoFlamingos26_2}
              className={style.img_mobile}
              alt="PhotoFlamingos26"
            ></img>
            <img
              src={PhotoFlamingos26_3}
              className={style.img_mobile}
              alt="PhotoFlamingos26"
            ></img>
            <img
              src={PhotoFlamingos26_4}
              className={style.img_mobile}
              alt="PhotoFlamingos26"
            ></img>
            <img
              src={PhotoFlamingos26_5}
              className={style.img_mobile}
              alt="PhotoFlamingos26"
            ></img>
            <img
              src={PhotoFlamingos26_6}
              className={style.img_mobile}
              alt="PhotoFlamingos26"
            ></img>
            <img
              src={PhotoFlamingos26_7}
              className={style.img_mobile}
              alt="PhotoFlamingos26"
            ></img>
            <img
              src={PhotoFlamingos26_8}
              className={style.img_mobile}
              alt="PhotoFlamingos26"
            ></img>
            <img
              src={PhotoFlamingos26_9}
              className={style.img_mobile}
              alt="PhotoFlamingos26"
            ></img>
            <img
              src={PhotoFlamingos26_10}
              className={style.img_mobile}
              alt="PhotoFlamingos26"
            ></img>
            <img
              src={PhotoFlamingos26_11}
              className={style.img_mobile}
              alt="PhotoFlamingos26"
            ></img>
          </div>
        </div>
        <Footer />
      </Mobile>
    </div>
  );
};

export default Flamingos26;
