import headerStyle from "../../Header/header.module.css";

import { Link } from "react-router-dom";
import { Desktop, Mobile } from "../../utils/resizeVersions";

const HeaderTitle = (props) => {
  return (
    <div className={headerStyle.header__textbox}>
      <Desktop>
        <Link to="/">
          <h1 className={headerStyle[props.headingColor]}>
            <span className={headerStyle.heading_primary_main}>
              Bespoke Estate
            </span>
            <h2 className={headerStyle.heading_primary_second}>MARBELLA</h2>
          </h1>
        </Link>
      </Desktop>
      <Mobile>
        <Link to="/">
          <h1 className={headerStyle[props.headingColor]}>
            <span className={headerStyle.heading_primary_main_mobile}>
              Bespoke Estate
            </span>
            <h2 className={headerStyle.heading_primary_second_mobile}>
              MARBELLA
            </h2>
          </h1>
        </Link>
      </Mobile>
    </div>
  );
};

export default HeaderTitle;
