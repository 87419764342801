import style from "./bacara.module.css";
import { useMediaQuery } from "react-responsive";
import Header from "../../Header/Header";
import Footer from "../../Footer/Footer";
import Bacara1 from "../../../assets/img/bacara1.jpg";
import Bacara2 from "../../../assets/img/bacara2.jpg";
import BacaraPdf from "../../../assets/pdf/Villa_Bacara.pdf";
import { Link } from "react-router-dom";
import Button from "../../UI/Button/Button";
import CardProjectDescription from "../../UI/CardProjectDescription/CardProjectDescription";

const Desktop = ({ children }) => {
  const isDesktop = useMediaQuery({ minWidth: 992 });
  return isDesktop ? children : null;
};

const Mobile = ({ children }) => {
  const isMobile = useMediaQuery({ maxWidth: 992 });
  return isMobile ? children : null;
};

const Bacara = (props) => {
  return (
    <div>
      <Desktop>
        <Header
          headingColor="heading_primary_projects"
          logoColor="header__logo_projects"
          headerType="header_projects"
          Bar1="bar1_projects"
          Bar2="bar2_projects"
          Bar3="bar3_projects"
        />
        <div className={style.bacara_project}>
          <CardProjectDescription
            title1="Villa Bacara"
            title2="Sierra Blanca"
            textDescription="The mansion is a real work of art that combines elegance and
              comfort. It is built on the spacious plot and provides a lot of
              privacy. A magnificent garden will be one of your favourite places
              for taking a walk and relaxing after a long day. The villa
              consists of 6 bedrooms and 9 bathrooms."
          />
          <div>
            <Link to={BacaraPdf} target="_blank" rel="noreferrer">
              <Button />
            </Link>
          </div>
          <div className={style.bakara_photos}>
            <img src={Bacara1} alt="Bacara1"></img>
            <img src={Bacara2} alt="Bacara2"></img>
          </div>
        </div>
        <Footer />
      </Desktop>
      <Mobile>
        <Header
          headingColor="heading_primary_projects"
          logoColor="header__logo_projects"
          Bar1="bar1_projects"
          Bar2="bar2_projects"
          Bar3="bar3_projects"
        />
        <div className={style.bacara_project_mobile}>
          <CardProjectDescription
            title1="Villa Bacara"
            title2="Sierra Blanca"
            textDescription="The mansion is a real work of art that combines elegance and
              comfort. It is built on the spacious plot and provides a lot of
              privacy. A magnificent garden will be one of your favourite places
              for taking a walk and relaxing after a long day. The villa
              consists of 6 bedrooms and 9 bathrooms."
          />

          <div>
            <Link to={BacaraPdf} target="_blank" rel="noreferrer">
              <Button />
            </Link>
          </div>
          <div className={style.bakara_photos_mobile}>
            <img src={Bacara1} className={style.img_mobile} alt="Bacara1"></img>
            <img src={Bacara2} className={style.img_mobile} alt="Bacara2"></img>
          </div>
        </div>
        <Footer />
      </Mobile>
    </div>
  );
};

export default Bacara;
