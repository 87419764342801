import "./App.css";
import { Route, Switch } from "react-router-dom";
import Projects from "./components/Pages/Projects/Projects";
import Home from "./components/Pages/Home/Home";
import { BrowserRouter as Router } from "react-router-dom";
import Bacara from "./components/Pages/Bacara/Bacara";
import Epic from "./components/Pages/Bacara/Epic";
import Flamingos26 from "./components/Pages/Bacara/Flamingos26";
import Flamingos30 from "./components/Pages/Bacara/Flamingos30";
import ScrollToTop from "./components/utils/ScrollToTop";

function App() {
  return (
    <div>
      <Router>
        <ScrollToTop>
          <Switch>
            <Route exact path="/">
              <Home />
            </Route>
            <Route path="/projects">
              <Projects />
            </Route>
            <Route path="/bacara">
              <Bacara />
            </Route>
            <Route path="/flamingos26">
              <Flamingos26 />
            </Route>
            <Route path="/flamingos30">
              <Flamingos30 />
            </Route>
            <Route path="/epic">
              <Epic />
            </Route>
          </Switch>
        </ScrollToTop>
      </Router>
    </div>
  );
}

export default App;
