import HeaderTitle from "../Header/HeaderTitle/HeaderTitle";
import { Desktop, Mobile } from "../utils/resizeVersions";
import style from "./footer.module.css";

const Footer = (props) => {
  return (
    <div>
      <Desktop>
        <HeaderTitle headingColor="heading_primary_projects" />
        <footer>
          <div className={style.footer_menu_wrapper}>
            <div>About</div>
            <div>Projects</div>
            <div>Contact</div>
            <div>Login</div>
          </div>
        </footer>
      </Desktop>
      <Mobile>
        <HeaderTitle headingColor="heading_primary_projects" />
        <footer>
          <div className={style.footer_menu_wrapper_mobile}>
            <div>About</div>
            <div>Projects</div>
            <div>Contact</div>
            <div>Login</div>
          </div>
        </footer>
      </Mobile>
    </div>
  );
};

export default Footer;
