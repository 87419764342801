import headerStyle from "../../Header/header.module.css";

import { Link } from "react-router-dom";

const Logo = (props) => {
  return (
    <div className={headerStyle.header__logo_box}>
      <Link to="/">
        <div className={headerStyle[props.logoColor]}></div>
      </Link>
    </div>
  );
};

export default Logo;
