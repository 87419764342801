import style from "./button.module.css";

const Button = () => {
  return (
    <div>
      <button type="button" className={style.buttonPDF}>
        <span>Open PDF</span>
      </button>
    </div>
  );
};

export default Button;
