import { Desktop, Mobile } from "../../utils/resizeVersions";
import style from "./cardProjectDescription.module.css";

const CardProjectDescription = (props) => {
  return (
    <div>
      <Desktop>
        <div className={style.text_describe_wrapper}>
          <p className={style.text_describe}>
            <h2 className={style.project_main_header}>{props.title1}</h2>
            <h3 className={style.project_header}>{props.title2}</h3>
            {props.textDescription}
          </p>
        </div>
      </Desktop>
      <Mobile>
        <div className={style.text_describe_wrapper}>
          <p className={style.text_describe}>
            <h2 className={style.project_main_header}>{props.title1}</h2>
            <h3 className={style.project_header}>{props.title2}</h3>
            {props.textDescription}
          </p>
        </div>
      </Mobile>
    </div>
  );
};

export default CardProjectDescription;
