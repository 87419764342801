import style from "../Gallery/gallery.module.css";

import { Link } from "react-router-dom";

const CardGallery1 = (props) => {
  return (
    <div className={style.card1Wrapper}>
      <div className={style.containerCard1}>
        <img src={props.img} alt="Villa" className={style.img} />
        <div className={style.overlay}>
          <div className={style.text}>
            <div className={style.textHeader}>{props.cardTitle}</div>
            <div className={style.textDescription}>{props.textDescription}</div>
            <div>
              <Link to={props.linkToProject} className={style.link_more}>
                MORE
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className={style.containerCard1_2}>
        <img src={props.img2} alt="Villa" className={style.img2} />
      </div>
    </div>
  );
};

export default CardGallery1;
