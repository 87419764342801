import React from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
import Popup from "reactjs-popup";
import style from "../BurgerMenu/burgerMenuStyle.module.css";
import MenuButton from "./MenuButton";

function Menu({ close }) {
  const [show, setShow] = useState(true);
  const [open, setOpen] = useState(false);
  const [openContact, setOpenContact] = useState(false);
  const [openLogin, setOpenLogin] = useState(false);

  const closeModal = () => {
    setOpen(false);
    setShow(true);
  };
  const closeModalContact = () => {
    setOpenContact(false);
    setShow(true);
  };
  const closeModalLogin = () => {
    setOpenLogin(false);
    setShow(true);
  };

  return (
    <div>
      <div className="menu">
        <div
          className="column_menu"
          style={{ visibility: show ? "visible" : "hidden" }}
        >
          <div className="block">
            <MenuButton
              menuTitle="About"
              onClick={() => {
                setOpen((o) => !o);
                setShow((s) => !s);
              }}
            />
            <Popup open={open} closeOnDocumentClick onClose={closeModal}>
              <div className={style.popup}>
                <div>
                  Secure your fiat, digital and crypto currencies in real estate
                  and other investment projects. Stable financial growth and
                  return on investments. Powered by strong team of financial and
                  legal advisors, we provide bespoke investment opportunities to
                  our valued clients.
                </div>
                <div>
                  <p className={style.close} onClick={closeModal}>
                    ←
                  </p>
                </div>
              </div>
            </Popup>
          </div>

          <Link to="/projects" onClick={close}>
            Projects
          </Link>

          <div className="block">
            <MenuButton
              menuTitle="Contact"
              onClick={() => {
                setOpenContact((o) => !o);
                setShow((s) => !s);
              }}
            />
            <Popup
              open={openContact}
              closeOnDocumentClick
              onClose={closeModalContact}
            >
              <div className={style.popup}>
                <div className={style.menu_contacts}>
                  <p>info@bespokestate.com</p>
                  <p>+34 627 195 806</p>
                  <p>23 Av los Vientos Marbella, Spain</p>
                </div>
                <div>
                  <p className={style.close} onClick={closeModalContact}>
                    ←
                  </p>
                </div>
              </div>
            </Popup>
          </div>
          <div className="block">
            <MenuButton
              menuTitle="Login"
              onClick={() => {
                setShow((s) => !s);
                setOpenLogin((o) => !o);
              }}
            />
            <Popup
              open={openLogin}
              closeOnDocumentClick
              onClose={closeModalLogin}
            >
              <div className={style.popup}>
                <div>
                  <div className={style.bg_body}>
                    <form>
                      <div className={style.form_field}>
                        <input
                          type="email"
                          autocomplete="off"
                          placeholder="Email"
                          required
                        />
                      </div>
                      <div className={style.form_field}>
                        <input
                          type="password"
                          autocomplete="off"
                          placeholder="PASSWORD"
                          required
                        />
                      </div>
                      <div>
                        <button className={style.btn} type="submit">
                          LOGIN
                        </button>
                      </div>
                    </form>
                  </div>
                  <div className={style.close} onClick={closeModalLogin}>
                    ←
                  </div>
                </div>
              </div>
            </Popup>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Menu;
