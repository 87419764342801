import Header from "../../Header/Header";
import Video2 from "../../Video/Video2";

const Home = (props) => {
  return (
    <div>
      <Video2 />
      <Header
        headingColor="heading_primary"
        logoColor="header__logo"
        menuColor="burger-menu"
        headerType="header"
        Bar1="bar1"
        Bar2="bar2"
        Bar3="bar3"
      />
    </div>
  );
};

export default Home;
