import style from "./bacara.module.css";
import Header from "../../Header/Header";
import Footer from "../../Footer/Footer";
import PhotoFlamingos30_1 from "../../../assets/villas/losFlamingos30/1.jpg";
import PhotoFlamingos30_2 from "../../../assets/villas/losFlamingos30/2.jpg";
import PhotoFlamingos30_3 from "../../../assets/villas/losFlamingos30/3.jpg";
import PhotoFlamingos30_4 from "../../../assets/villas/losFlamingos30/4.jpg";
import PhotoFlamingos30_5 from "../../../assets/villas/losFlamingos30/5.jpg";
import PhotoFlamingos30_6 from "../../../assets/villas/losFlamingos30/6.jpg";
import PhotoFlamingos30_7 from "../../../assets/villas/losFlamingos30/7.jpg";
import PhotoFlamingos30_8 from "../../../assets/villas/losFlamingos30/8.jpg";
import PhotoFlamingos30_9 from "../../../assets/villas/losFlamingos30/9.jpg";
import PhotoFlamingos30_10 from "../../../assets/villas/losFlamingos30/10.jpg";
import PhotoFlamingos30_11 from "../../../assets/villas/losFlamingos30/11.jpg";
import PhotoFlamingos30_12 from "../../../assets/villas/losFlamingos30/12.jpg";
import PhotoFlamingos30_13 from "../../../assets/villas/losFlamingos30/13.jpg";
import PhotoFlamingos30_14 from "../../../assets/villas/losFlamingos30/14.jpg";
import PhotoFlamingos30_15 from "../../../assets/villas/losFlamingos30/15.jpg";
import PhotoFlamingos30_16 from "../../../assets/villas/losFlamingos30/16.jpg";
import PhotoFlamingos30_17 from "../../../assets/villas/losFlamingos30/17.jpg";
import PhotoFlamingos30_18 from "../../../assets/villas/losFlamingos30/18.jpg";
import PhotoFlamingos30_19 from "../../../assets/villas/losFlamingos30/19.jpg";
import PhotoFlamingos30_20 from "../../../assets/villas/losFlamingos30/20.jpg";
import PhotoFlamingos30_21 from "../../../assets/villas/losFlamingos30/21.jpg";
import PhotoFlamingos30_22 from "../../../assets/villas/losFlamingos30/22.jpg";
import PhotoFlamingos30_23 from "../../../assets/villas/losFlamingos30/23.jpg";
import Flamingos30Pdf from "../../../assets/pdf/Villa_LosFlamingos_30.pdf";
import { Link } from "react-router-dom";
import Button from "../../UI/Button/Button";
import { Desktop, Mobile } from "../../utils/resizeVersions";
import CardProjectDescription from "../../UI/CardProjectDescription/CardProjectDescription";

const Flamingos30 = (props) => {
  return (
    <div>
      <Desktop>
        <Header
          headingColor="heading_primary_projects"
          logoColor="header__logo_projects"
          headerType="header_projects"
          Bar1="bar1_projects"
          Bar2="bar2_projects"
          Bar3="bar3_projects"
        />
        <div className={style.bacara_project}>
          <CardProjectDescription
            title1="Villa Los Flamingos"
            title2="Benahavis"
            textDescription="Villa Los Flamingos 30 is an exquisite and luxurious property
            built in a unique environment, balancing form, texture and space
            to create the best home. It was designed according to the highest
            market standards and the most avant-garde technology to know at
            all times the state of the home and offer maximum comfort. This
            impressive villa is specially designed to offer breathtaking
            panoramic views of the beautiful surroundings and the Mediter-
            ranean Sea. A guarded private urbanization allows you to feel calm
            and safe."
          />

          <div>
            <Link to={Flamingos30Pdf} target="_blank" rel="noreferrer">
              <Button />
            </Link>
          </div>
          <div className={style.bakara_photos}>
            <img src={PhotoFlamingos30_1} alt="PhotoFlamingos30"></img>
            <img src={PhotoFlamingos30_2} alt="PhotoFlamingos30"></img>
            <img src={PhotoFlamingos30_3} alt="PhotoFlamingos30"></img>
            <img src={PhotoFlamingos30_4} alt="PhotoFlamingos30"></img>
            <img src={PhotoFlamingos30_5} alt="PhotoFlamingos30"></img>
            <img src={PhotoFlamingos30_6} alt="PhotoFlamingos30"></img>
            <img src={PhotoFlamingos30_7} alt="PhotoFlamingos30"></img>
            <img src={PhotoFlamingos30_8} alt="PhotoFlamingos30"></img>
            <img src={PhotoFlamingos30_9} alt="PhotoFlamingos30"></img>
            <img src={PhotoFlamingos30_10} alt="PhotoFlamingos30"></img>
            <img src={PhotoFlamingos30_11} alt="PhotoFlamingos30"></img>
            <img src={PhotoFlamingos30_12} alt="PhotoFlamingos30"></img>
            <img src={PhotoFlamingos30_13} alt="PhotoFlamingos30"></img>
            <img src={PhotoFlamingos30_14} alt="PhotoFlamingos30"></img>
            <img src={PhotoFlamingos30_15} alt="PhotoFlamingos30"></img>
            <img src={PhotoFlamingos30_16} alt="PhotoFlamingos30"></img>
            <img src={PhotoFlamingos30_17} alt="PhotoFlamingos30"></img>
            <img src={PhotoFlamingos30_18} alt="PhotoFlamingos30"></img>
            <img src={PhotoFlamingos30_19} alt="PhotoFlamingos30"></img>
            <img src={PhotoFlamingos30_20} alt="PhotoFlamingos30"></img>
            <img src={PhotoFlamingos30_21} alt="PhotoFlamingos30"></img>
            <img src={PhotoFlamingos30_22} alt="PhotoFlamingos30"></img>
            <img src={PhotoFlamingos30_23} alt="PhotoFlamingos30"></img>
          </div>
        </div>
        <Footer />
      </Desktop>
      <Mobile>
        <Header
          headingColor="heading_primary_projects"
          logoColor="header__logo_projects"
          Bar1="bar1_projects"
          Bar2="bar2_projects"
          Bar3="bar3_projects"
        />
        <div className={style.bacara_project_mobile}>
          <CardProjectDescription
            title1="Villa Los Flamingos"
            title2="Benahavis"
            textDescription="Villa Los Flamingos 30 is an exquisite and luxurious property
            built in a unique environment, balancing form, texture and space
            to create the best home. It was designed according to the highest
            market standards and the most avant-garde technology to know at
            all times the state of the home and offer maximum comfort. This
            impressive villa is specially designed to offer breathtaking
            panoramic views of the beautiful surroundings and the Mediter-
            ranean Sea. A guarded private urbanization allows you to feel calm
            and safe."
          />

          <div>
            <Link to={Flamingos30Pdf} target="_blank" rel="noreferrer">
              <Button />
            </Link>
          </div>
          <div className={style.bakara_photos_mobile}>
            <img
              src={PhotoFlamingos30_1}
              className={style.img_mobile}
              alt="PhotoFlamingos30"
            ></img>
            <img
              src={PhotoFlamingos30_2}
              className={style.img_mobile}
              alt="PhotoFlamingos30"
            ></img>
            <img
              src={PhotoFlamingos30_3}
              className={style.img_mobile}
              alt="PhotoFlamingos30"
            ></img>
            <img
              src={PhotoFlamingos30_4}
              className={style.img_mobile}
              alt="PhotoFlamingos30"
            ></img>
            <img
              src={PhotoFlamingos30_5}
              className={style.img_mobile}
              alt="PhotoFlamingos30"
            ></img>
            <img
              src={PhotoFlamingos30_6}
              className={style.img_mobile}
              alt="PhotoFlamingos30"
            ></img>
            <img
              src={PhotoFlamingos30_7}
              className={style.img_mobile}
              alt="PhotoFlamingos30"
            ></img>
            <img
              src={PhotoFlamingos30_8}
              className={style.img_mobile}
              alt="PhotoFlamingos30"
            ></img>
            <img
              src={PhotoFlamingos30_9}
              className={style.img_mobile}
              alt="PhotoFlamingos30"
            ></img>
            <img
              src={PhotoFlamingos30_10}
              className={style.img_mobile}
              alt="PhotoFlamingos30"
            ></img>
            <img
              src={PhotoFlamingos30_11}
              className={style.img_mobile}
              alt="PhotoFlamingos30"
            ></img>
            <img
              src={PhotoFlamingos30_12}
              className={style.img_mobile}
              alt="PhotoFlamingos30"
            ></img>
            <img
              src={PhotoFlamingos30_13}
              className={style.img_mobile}
              alt="PhotoFlamingos30"
            ></img>
            <img
              src={PhotoFlamingos30_14}
              className={style.img_mobile}
              alt="PhotoFlamingos30"
            ></img>
            <img
              src={PhotoFlamingos30_15}
              className={style.img_mobile}
              alt="PhotoFlamingos30"
            ></img>
            <img
              src={PhotoFlamingos30_16}
              className={style.img_mobile}
              alt="PhotoFlamingos30"
            ></img>
            <img
              src={PhotoFlamingos30_17}
              className={style.img_mobile}
              alt="PhotoFlamingos30"
            ></img>
            <img
              src={PhotoFlamingos30_18}
              className={style.img_mobile}
              alt="PhotoFlamingos30"
            ></img>
            <img
              src={PhotoFlamingos30_19}
              className={style.img_mobile}
              alt="PhotoFlamingos30"
            ></img>
            <img
              src={PhotoFlamingos30_20}
              className={style.img_mobile}
              alt="PhotoFlamingos30"
            ></img>
            <img
              src={PhotoFlamingos30_21}
              className={style.img_mobile}
              alt="PhotoFlamingos30"
            ></img>
            <img
              src={PhotoFlamingos30_22}
              className={style.img_mobile}
              alt="PhotoFlamingos30"
            ></img>
            <img
              src={PhotoFlamingos30_23}
              className={style.img_mobile}
              alt="PhotoFlamingos30"
            ></img>
          </div>
        </div>
        <Footer />
      </Mobile>
    </div>
  );
};

export default Flamingos30;
