import style from "../BurgerMenu/burgerMenuStyle.module.css";

const MenuButton = (props) => {
  return (
    <>
      <button type="button" className={style.button} onClick={props.onClick}>
        {props.menuTitle}
      </button>
    </>
  );
};

export default MenuButton;
