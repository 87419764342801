import style from "./gallery.module.css";
import Bacara1 from "./../../assets/img/bacara1.jpg";
import Bacara2 from "./../../assets/img/bacara2.jpg";
import Flamingos26 from "./../../assets/img/flamingos26.jpg";
import Flamingos26_2 from "./../../assets/villas/losFlamingos26/4.jpg";
import Epic from "./../../assets/villas/epic/2.jpg";
import Epic_2 from "./../../assets/villas/epic/7.jpg";

import Flamingos30 from "./../../assets/img/flamingos30.jpg";
import Flamingos30_2 from "./../../assets/img/flamingos30_2.jpg";
import { Link } from "react-router-dom";
import { Desktop, Mobile } from "../../components/utils/resizeVersions";
import CardGallery1 from "./CardGallery1";
import CardGallery2 from "./CardGallery2";

const Gallery = () => {
  return (
    <div>
      <Desktop>
        <div className={style.wrapper}>
          <CardGallery1
            img={Epic_2}
            img2={Epic}
            cardTitle="EPIC Marbella"
            textDescription="We all seek our place in the sun that setting where peace, purpose,
            and prosperity converge creating the perfect environment for your
            life a locale with a trinity of views, ancient and majestic
            mountains rise to the north, the deep blue sea exhales to the south,
            and lush valleysfall to the west where your every desire has been
            considered ever elusive, until now..."
            linkToProject="/epic"
          />
          <CardGallery2
            img={Flamingos30_2}
            img2={Flamingos30}
            cardTitle="Villa Los Flamingos"
            textDescription="Newly built Villa in Los Flamingos room is now available for
                    the purchase! This fantastic property feature 5 bedrooms
                    with en-suite bathrooms and great views, do the plot being
                    on the corner. This house is a fantastic property for the
                    ones looking for their families or for the ones who are
                    looking for an investment project."
            linkToProject="/flamingos30"
          />
          <CardGallery1
            img={Flamingos26_2}
            img2={Flamingos26}
            cardTitle="Villa Egret"
            textDescription="Villa Egret is an exquisite and luxurious property built in a
            unique environment, balancing form, texture and space to
            create the best home. It was designed according to the highest
            market standards and the most avant-garde technology to know
            at all times the state of the home and offer maximum comfort."
            linkToProject="/flamingos26"
          />
          <CardGallery2
            img={Bacara2}
            img2={Bacara1}
            cardTitle="Villa Bacara"
            textDescription="The mansion is a real work of art that combines elegance and
            comfort. It is built on the spacious plot and provides a lot
            of privacy. A magnificent garden will be one of your favourite
            places for taking a walk and relaxing after a long day. The
            villa consists of 6 bedrooms and 9 bathrooms."
            linkToProject="/bacara"
          />
        </div>
      </Desktop>
      <Mobile>
        <div className={style.wrapper_mobile}>
          <div className={style.mainImg}>
            <img src={Epic} alt="g" className={style.imgStyle}></img>
          </div>
          <div className={style.project_describe}>
            <div className={style.project_describe_heading}>EPIC</div>
            We all seek our place in the sun that setting where peace, purpose,
            and prosperity converge creating the perfect environment for your
            life a locale with a trinity of views, ancient and majestic
            mountains rise to the north, the deep blue sea exhales to the south,
            and lush valleysfall to the west where your every desire has been
            considered ever elusive, until now...
            <div>
              <Link to="/epic" className={style.link_more_mobile}>
                MORE
              </Link>
            </div>
          </div>
        </div>
        <div className={style.wrapper_mobile}>
          <div className={style.mainImg}>
            <img src={Bacara1} alt="g" className={style.imgStyle}></img>
          </div>
          <div className={style.project_describe}>
            <div className={style.project_describe_heading}>Villa Bacara</div>
            The mansion is a real work of art that combines elegance and
            comfort. It is built on the spacious plot and provides a lot of
            privacy. A magnificent garden will be one of your favourite places
            for taking a walk and relaxing after a long day. The villa consists
            of 6 bedrooms and 9 bathrooms.
            <div>
              <Link to="/bacara" className={style.link_more_mobile}>
                MORE
              </Link>
            </div>
          </div>
        </div>
        <div className={style.wrapper_mobile}>
          <div className={style.mainImg}>
            <img src={Flamingos26} alt="g" className={style.imgStyle}></img>
          </div>
          <div className={style.project_describe}>
            <div className={style.project_describe_heading}>Villa Egret</div>
            Villa Egret is an exquisite and luxurious property built in a unique
            environment, balancing form, texture and space to create the best
            home. It was designed according to the highest market standards and
            the most avant-garde technology to know at all times the state of
            the home and offer maximum comfort.
            <div>
              <Link to="/flamingos26" className={style.link_more_mobile}>
                MORE
              </Link>
            </div>
          </div>
        </div>
        <div className={style.wrapper_mobile}>
          <div className={style.mainImg}>
            <img src={Flamingos30} alt="g" className={style.imgStyle}></img>
          </div>
          <div className={style.project_describe}>
            <div className={style.project_describe_heading}>Los Flamingos</div>
            Villa Los Flamingos 30 is an exquisite and luxurious property built
            in a unique environment, balancing form, texture and space to create
            the best home. It was designed according to the highest market
            standards and the most avant-garde technology to know at all times
            the state of the home and offer maximum comfort.
            <div>
              <Link to="/flamingos30" className={style.link_more_mobile}>
                MORE
              </Link>
            </div>
          </div>
        </div>
      </Mobile>
    </div>
  );
};

export default Gallery;
