import Footer from "../../Footer/Footer";
import Gallery from "../../Gallery/Gallery";
import Header from "../../Header/Header";
import { Desktop, Mobile } from "../../utils/resizeVersions";
import projectsStyle from "../Projects/projects.module.css";

const Projects = (props) => {
  return (
    <div>
      <Desktop>
        <div className={projectsStyle.projects}>
          <Header
            headingColor="heading_primary_projects"
            logoColor="header__logo_projects"
            headerType="header_projects"
            Bar1="bar1_projects"
            Bar2="bar2_projects"
            Bar3="bar3_projects"
          />
          <Gallery />
          <Footer />
        </div>
      </Desktop>
      <Mobile>
        <div className={projectsStyle.projects_mobile}>
          <Header
            headingColor="heading_primary_projects"
            logoColor="header__logo_projects"
            Bar1="bar1_projects"
            Bar2="bar2_projects"
            Bar3="bar3_projects"
          />
          <Gallery />
          <Footer />
        </div>
      </Mobile>
    </div>
  );
};

export default Projects;
